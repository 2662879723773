import "./src/styles/global.css"
import '@fontsource/readex-pro/500.css'
import '@fontsource/readex-pro/700.css'
import '@fontsource/manrope/300.css'
import React from "react"
import theme from './src/theme'
import {ChakraProvider} from '@chakra-ui/react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
    return <GoogleReCaptchaProvider reCaptchaKey="6LeVzkEkAAAAAIOiZCAdkjnYoRp1wWNvpjgMYXyk">
      <ChakraProvider theme={theme} {...props}>{element}</ChakraProvider>
    </GoogleReCaptchaProvider>
  }