import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
    styles: {
      global: {
        h1: {
          color: 'rgb(43,87,98)',
        },
        h2: {
          color: 'rgb(43,87,98)',
        },
        p: {
          color: 'rgb(166,168,153)',
        }
      },
    },
    fonts: {
      body: '"Manrope", sans-serif',
      heading: '"Readex Pro", sans-serif',
    }
  })
  
export default theme